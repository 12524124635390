

.kiwi-controlinput {
    z-index: 2;
    position: relative;
    max-height: 40%;
}

.kiwi-controlinput,
.kiwi-controlinput-inner {
    padding: 0;
    box-sizing: border-box;
    transition: width 0.2s;
    transition-delay: 0.2s;
}

.kiwi-controlinput-inner {
    display: flex;
    position: relative;
    height: 100%;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.05);
    height: 42px;
    align-items: center;
    color: var(--brand-darktone);

    .kiwi-awaystatusindicator {
        margin-top: 14px;
        margin-left: 10px;
        margin-right: -2px;
    }
}

.kiwi-controlinput-user {
    height: 100%;
    padding-left: 10px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    line-height: 38px;
    transition: width 0.2s 0.1s;

    > i {
        font-size: 120%;
        margin-left: 8px;
    }
}

.kiwi-controlinput--selfuser-open {
    .kiwi-controlinput-inner > .kiwi-awaystatusindicator {
        visibility: hidden;
    }

    .kiwi-controlinput-user {
        width: 296px;
        visibility: hidden;
    }

    .kiwi-controlinput-selfuser {
        width: 324px;
        max-height: 300px;
        opacity: 1;
    }
}

.kiwi-controlinput-form {
    flex: 1;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
}

.kiwi-controlinput-command-warn {
    position: absolute;
    bottom: 100%;
    margin-left: 10px;
    padding: 6px 10px 10px 10px;
    border: 1px solid;
    border-radius: 10px 10px 0 0;
    z-index: 1;

    .kiwi-controlinput-command-text {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 10px;
        font-weight: 700;
    }

    .fa-exclamation-triangle {
        margin-right: 2px;
    }

    .u-input-confirm {
        padding: initial;
        padding-left: 10px;
    }
}

.kiwi-controlinput-input {
    text-align: left;
    height: 100%;
    outline: none;
    border: none;
}

.kiwi-controlinput-input-wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 10px;
}

.kiwi-controlinput-active-tool {
    position: absolute;
    bottom: calc(100% + 1px);
    right: 74px;
    left: 0;
    z-index: 1;
}

.kiwi-controlinput-selfuser {
    position: absolute;
    bottom: 0;
    z-index: 10;
    left: 0;
    max-height: 0;
    width: 324px;
    box-sizing: border-box;
    border-radius: 0 6px 0 0;
    opacity: 0;
    border-top: 1px solid;
    border-right: 1px solid;
    overflow: hidden;
}

.kiwi-selfuser-trans-enter,
.kiwi-selfuser-trans-leave-to {
    opacity: 0;
    height: 0;
}

.kiwi-selfuser-trans-enter-to,
.kiwi-selfuser-trans-leave {
    opacity: 1;
}

.kiwi-selfuser-trans-enter-active,
.kiwi-selfuser-trans-leave-active {
    transition: all 0.4s;
}

.kiwi-controlinput-tools {
    border-radius: 8px;
    padding: 1px;
    height: 36px;
}

.kiwi-controlinput-tools-expand > i {
    transition: transform 0.2s;
}

.kiwi-controlinput-tools-expand--closed > i {
    transform: rotateZ(180deg);
}

.kiwi-controlinput-send {
    padding: 1px 6px;
}

.kiwi-controlinput--show-send.kiwi-controlinput--show-tools {
    // The send button and tools are visible, merge their borders
    .kiwi-controlinput-tools-wrapper {
        border-radius: 0 8px 8px 0;
        padding: 1px 1px 1px 0;
    }

    .kiwi-controlinput-send-container {
        border-radius: 8px 0 0 8px;
        padding: 1px 0 1px 1px;
    }
}

.kiwi-controlinput-tools-container {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    padding: 1px;
    border-radius: 8px;
    position: absolute;
    bottom: calc(100% + 1px);
    top: auto;
    right: 0;
    width: 72px;
}

.kiwi-controlinput--show-tools--inline {
    .kiwi-controlinput-tools-container {
        flex-direction: row;
        position: relative;
        width: auto;
        top: 0;
        padding: 0;
    }

    .kiwi-controlinput-active-tool {
        right: 0;
        background-color: rgba(5, 11, 17, 0.55);
        display: flex;
        justify-content: center;
    }
}

.kiwi-controlinput-button {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin: 1px;
    text-align: center;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;

    i {
        font-size: 20px;
        line-height: 32px;
        margin: 0;
    }
}

.kiwi-plugin-ui-trans-enter,
.kiwi-plugin-ui-trans-leave-to {
    right: -100%;
}

.kiwi-plugin-ui-trans-enter-to,
.kiwi-plugin-ui-trans-leave {
    right: 0;
}

.kiwi-plugin-ui-trans-enter-active,
.kiwi-plugin-ui-trans-leave-active {
    transition: right 0.2s;
}

@media screen and (max-width: 500px) {
    .kiwi-controlinput-user-nick {
        display: none;
    }

    .kiwi-controlinput-user > i {
        margin-left: 0;
    }
}

@media screen and (max-width: 769px) {
    .kiwi-controlinput--selfuser-open .kiwi-controlinput-selfuser {
        width: 100%;
        border-radius: 0;
        border-right: 0;
    }

    .kiwi-wrap--statebrowser-drawopen .kiwi-controlinput {
        z-index: 0;
    }

    // hide the control input on narrow screens when the self user box is open
    .kiwi-controlinput--selfuser-open .kiwi-controlinput-inner {
        display: none;
    }

    .kiwi-controlinput-tools-container {
        width: 34px;
    }

    .kiwi-controlinput-active-tool {
        right: 36px;
    }
}



.embedly-card {
    display: block;
    margin: 4px 0;
}

.kiwi-mediaviewer-embedly {
    display: inline-block;
    overflow: auto;
}

.kiwi-main-mediaviewer .kiwi-mediaviewer-embedly {
    display: block;
}


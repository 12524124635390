
.kiwi-mediaviewer {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
}

.kiwi-mediaviewer-controls {
    position: absolute;
    top: 0;
    right: 16px;
    z-index: 1;
    padding: 3px;
    opacity: 0;
    transition: opacity 0.1s;
}

.kiwi-mediaviewer:hover .kiwi-mediaviewer-controls {
    opacity: 1;
}

.kiwi-mediaviewer-controls-close {
    padding: 3px 15px;
}

.kiwi-mediaviewer-content {
    height: 100%;
    overflow: auto;
}

.kiwi-mediaviewer-iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    border: none;
}

@media screen and (max-width: 769px) {
    .kiwi-mediaviewer-controls {
        right: 6px;
        opacity: 1;
    }

    .kiwi-mediaviewer-controls-close {
        padding: 3px 7px;
    }
}
